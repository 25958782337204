@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
  
.skeleton-loader-title-desktop {
  width: 100%;
  height: 24px;
  background-color: #f0f0f0;
  animation: pulse 2s infinite ease-in-out;
  border-radius: 10px;
}