
@import "react-datepicker/dist/react-datepicker.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.highlight {
  background: rgb(76, 111, 255);
  opacity: 0.75;
  color: white;
}

body {
  background: linear-gradient(180deg,#F4F8F9 0%,#F4F8F9 100%);
  height: 100%;
  overflow-x: hidden;
}

.react-calendar {
  border: 0px solid #a0a096 !important;
}

@font-face {
  font-family: 'Nexa-Bold';
  src: url('https://flowroom.com/nexa_light.woff2') format('woff2'),
       url('https://flowroom.com/nexa_light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

#right-menu-desktop-wrap {
  display: block;
}

@media (max-width: 1000px) {
  #right-menu-desktop-wrap {
    display: none;
  }
}


#left-menu-desktop-wrap {
  display: block;
}

@media (max-width: 1000px) {
  #left-menu-desktop-wrap {
    display: none;
  }
}

#desktop-content {
  display: block;
}

@media (max-width: 1000px) {
  #desktop-content {
    display: none;
  }
  
}

.mobile-content {
  display: none;
}

@media (max-width: 1000px) {
  .mobile-content  {
    display: flex;
    max-width: 530px;
    margin:auto;
    padding: 17px !important;
    
  }
}

.button {
  background-color: rgb(76, 111, 255);
}

.button:hover {
  background-color: rgb(86, 121, 255);
}

.button:active {
  background-color: rgb(66, 101, 245);
}

.button {
  background-color: rgb(76, 111, 255);
  /* Add other necessary styles, such as padding, border, etc. */
  transition: background-color 0.3s ease;
}

html {
  overflow: hidden;
}

#menu {
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 7px inset !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
}

.accounts-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
}

.accounts-table thead {
  background-color: #f9f9f9;
}

.accounts-table th {
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  color: #555;
}

.accounts-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.accounts-table tbody tr:hover {
  background-color: #f5f5f5;
}

.accounts-table td {
  padding: 10px;
}

@media (max-width: 768px) {
  .accounts-table th,
  .accounts-table td {
    font-size: 12px;
    padding: 8px;
  }
}

.posts-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
}

.posts-table thead {
  background-color: #f9f9f9;
}

.posts-table th {
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  color: #555;
}

.posts-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.posts-table tbody tr:hover {
  background-color: #f5f5f5;
}

.posts-table td {
  padding: 10px;
}