@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

.skeleton-loader-wordcount-desktop {
  width: 104px;
  height: 28px;
  background-color: #f0f0f0;
  display: inline-block;
  animation: pulse 2s infinite ease-in-out;
  border-radius: 10px;
}