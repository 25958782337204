.card {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  display: flex;
  background: white;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  font-family:'nexa_lightregular';
}

.card-image {
  width: 30%;
  object-fit: cover;
}

.card-content {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text {
  font-size: 11px;
  max-width: 300px;
  width: 100%;
}

.cardHashtags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.cardButton {
  right:0px;
  position: absolute;
}

.cardButton {
  height: 45px;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: 0;
  font-family: 'nexa_boldregular';
  position: relative;
  width: calc(100% - 25px);
  margin: auto;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  background-color: rgb(76, 111, 255);
}

.cardButton:hover {
  background-color: rgb(86, 121, 255);
}

.cardButton:active {
  background-color: rgb(66, 101, 245);
}