.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    width: 100%;
    height: 500px;
    border-radius: 20px;
    display: flex;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    flex-direction: column;
    align-items: center;
  }

  .input-field {
    width: 400px;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #ccc;
    transition: border-bottom 0.3s;
    outline: none;
  }
  
  .input-field:focus {
    border-bottom: 2px solid #4C6FFF;
  }
  
  .button {
    width: 400px;
    height: 64px;
    background-color: #4C6FFF;
    border: none;
    color: white;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .googleLogin {
    width: 100%;
    height: 50px;
    background-color: white;
    border: none;
    color: black;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
    /* border: 1px solid black; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    font-family: nexa_boldregular;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 110px;
  }
  