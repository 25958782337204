@font-face {
  font-family: "nexa_boldregular";
  src: url("/src/fonts/Nexa\ Bold.woff2") format("woff2"),
    url("/src/fonts/Nexa\ Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa_lightregular";
  src: url("/src/fonts/Nexa\ Light.woff2") format("woff2"),
    url("/src/fonts/Nexa\ Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

input {
  font-family: nexa_lightregular;
}