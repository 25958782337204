@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
  
.skeleton-loader-desktop {
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
  display: inline-block;
  animation: pulse 2s infinite ease-in-out;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 10px;
} 